$(document).ready(function() {
  $("#itemGallery").lightGallery({
    selector: '.product-item-gallery',
    useLeft: true,
    thumbnail: true,
    animateThumb: false,
    showThumbByDefault: false,
    zoom: false,
  });
});

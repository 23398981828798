$(function() {

	scrollFunction();

	$(window).on("scroll", scrollFunction);

	function scrollFunction() {
		if ($(window).scrollTop() > 100) {
			$(".lpage2").addClass("scrolling");
		} else {
			$(".lpage2").removeClass("scrolling");
		}
	}

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('#lhp-banners4').slick({
		dots: false,
		infinite: true,
		speed: 1000,
		fade: true,
		rtl: dirRtlFlag,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 3000,
		cssEase: 'linear',
		pauseOnHover: false
	});

});

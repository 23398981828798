// // slider call
$(document).ready(function() {

	// var dirRtlFlag = false;
  // if ($("html").attr('dir') == 'rtl') {
  //   dirRtlFlag = true;
  // }


 $('#branch-slider').slick({
     slidesToShow: 1,
     slidesToScroll: 1,
     arrows: true,
     dots:false,
     fade: true,
     rtl: true,
     autoplay: false,
     asNavFor: '#branch-slider-nav'
  });
  $('#branch-slider-nav').slick({
     slidesToShow: 5,
     slidesToScroll: 1,
     asNavFor: '#branch-slider',
     dots: false,
     arrows:false,
     rtl: true,
     focusOnSelect: true,
     responsive: [
			 {
        breakpoint: 550,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      }
    ]
  });

});

$(document).ready(function() {
	$('.new-gallery-content').lightGallery({
		thumbnail: true,
		animateThumb: false,
		showThumbByDefault: false,
		selector: '.gallery-item-content'
	}); 


		$('.new-gallery-content').lightGallery({
		thumbnail: true,
		animateThumb: false,
		showThumbByDefault: false,
		selector: '.gallery-item-content'
	}); 


})
$(document).ready(function() {
	// When the window has finished loading create our google map below
	if ($("#map").length) {

		google.maps.event.addDomListener(window, 'load', initMap);
	}
	var geocoder;
	var map;
	var results_after;

	function initMap() {

		// Basic options for a simple Google Map
		// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
		geocoder = new google.maps.Geocoder();
		var address = document.getElementById('address').value;

		geocoder.geocode({
			'address': address
		}, function(results, status) {
			if (status == 'OK') {
				results_after = results;
				var mapOptions = {
					zoom: 15,
					disableDefaultUI: true,
					center: new google.maps.LatLng(results_after[0].geometry.location.lat() + 0.007, results_after[0].geometry.location.lng()),

					styles: [{
						"featureType": "all",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#3a3c2f"
						}]
					}, {
						"featureType": "all",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#d8d8d0"
						}]
					}, {
						"featureType": "all",
						"elementType": "labels.text.stroke",
						"stylers": [{
							"visibility": "on"
						}, {
							"color": "#000000"
						}, {
							"lightness": 16
						}]
					}, {
						"featureType": "all",
						"elementType": "labels.icon",
						"stylers": [{
							"visibility": "off"
						}]
					}, {
						"featureType": "administrative.locality",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#9b9f81"
						}]
					}, {
						"featureType": "administrative.locality",
						"elementType": "labels.text.stroke",
						"stylers": [{
							"color": "#2e311c"
						}]
					}, {
						"featureType": "administrative.land_parcel",
						"elementType": "geometry.stroke",
						"stylers": [{
							"color": "#65663a"
						}]
					}, {
						"featureType": "landscape",
						"elementType": "geometry",
						"stylers": [{
							"color": "#000000"
						}, {
							"lightness": 20
						}]
					}, {
						"featureType": "landscape",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#3b3c30"
						}]
					}, {
						"featureType": "landscape.man_made",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#3a3c2f"
						}]
					}, {
						"featureType": "poi",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#545535"
						}]
					}, {
						"featureType": "road.highway",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#707427"
						}]
					}, {
						"featureType": "road.highway",
						"elementType": "geometry.stroke",
						"stylers": [{
							"color": "#a7af3b"
						}]
					}, {
						"featureType": "road.arterial",
						"elementType": "geometry",
						"stylers": [{
							"color": "#2c2c2c"
						}]
					}, {
						"featureType": "road.local",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#323326"
						}]
					}, {
						"featureType": "road.local",
						"elementType": "geometry.stroke",
						"stylers": [{
							"color": "#4a5435"
						}]
					}, {
						"featureType": "transit.line",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#6f7527"
						}]
					}, {
						"featureType": "transit.station.airport",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#3e3e36"
						}]
					}, {
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [{
							"color": "#6f7527"
						}, {
							"lightness": 17
						}]
					}, {
						"featureType": "water",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#1d1d1d"
						}]
					}]
				};

				var mapElement = document.getElementById('map');

				map = new google.maps.Map(mapElement, mapOptions);
				var image = 'assets/images/loc-map.png';

				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(results_after[0].geometry.location.lat(), results_after[0].geometry.location.lng()),
					map: map,
					icon: image,
					title: ''
				});
			} else {
				console.log('Geocode was not successful for the following reason: ' + status);
			}
		});


	}

	function codeAddress() {
		var address = document.getElementById('address').value;
		geocoder.geocode({
			'address': address
		}, function(results, status) {
			if (status == 'OK') {
				map.setCenter(results[0].geometry.location);
				var marker = new google.maps.Marker({
					map: map,
					position: results[0].geometry.location
				});
			} else {
				alert('Geocode was not successful for the following reason: ' + status);
			}
		});
	}

});

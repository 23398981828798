$(function() {

  var dirRtlFlag = false;
  if ($("html").attr('dir') == 'rtl') {
    dirRtlFlag = true;
  }

  $('#hp-banners').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    rtl: dirRtlFlag,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    pauseOnHover: false
  });

  $(window).scroll(function(){
    var x = $(this).scrollTop(); /* scroll pos */
    var y = $("html").height();
    var z = x / y;

    curTop = (((z*800)+0));
    $("#banners-scroll").css("top", (curTop+0)+"px");
  });
  $('.box-slider-slick').slick({
		slidesToShow: 9,
		slidesToScroll: 1,
		dots: false,
		infinite: true,
		speed: 1000,
		rtl: dirRtlFlag,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
		cssEase: 'linear',
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 376,
				settings: {
					slidesToShow: 3,
				}
			}
		]
	});

 




  var hasWebRTC = navigator.getUserMedia ||
                  navigator.webkitGetUserMedia ||
                  navigator.mozGetUserMedia ||
                  navigator.msGetUserMedia;

  if (!hasWebRTC) {
    $('.wrap-banner-scroll').css('position','static');
  }
  
});

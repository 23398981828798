$(document).on('show.bs.tab', '.nav-tabs-responsive [data-toggle="tab"]', function(e) {
	var $target = $(e.target);
	var $tabs = $target.closest('.nav-tabs-responsive');
	var $current = $target.closest('li');
	var $next = $current.next();
	var $prev = $current.prev();
	var $focus = $current;

	$tabs.find('>li').removeClass('next prev active');
	$prev.addClass('prev');
	$next.addClass('next');
	$focus.addClass('active');
});

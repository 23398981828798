$(function(){

  if ($('body').find('#popap-enter')) {
    $.get('index.php?dir=site&page=forms&cs=5025', function( data ) {
      data = $(data);
      $("#popap-enter .custom-form").html(data.find(".custom-form"));
    })
  }


//   setTimeout(function() {
//     var Cookie3 = $.cookie('mouseleavePPSS1');
//     if( typeof(Cookie3) == 'undefined' ){
//       $('#popap-enter').modal('show');
//       $.cookie("mouseleavePPSS1", 1 , {expires: 1 });
//     }else{
//     }
//   }, 5000);

  $("body").on("submit", "#popap-enter form", function(e){
    var $form = $("#popap-enter form")
    $($form).find('.btn-form').prop('disabled', true);
    $.ajax({
           type: "POST",
           url: $form.attr("action"),
           data: $form.serialize(), // serializes the form's elements.
           success: function(data)
           {
                $($form).find('.btn-form').prop('disabled', false);
               data = $(data);
               
               if(data.find(".custom-form").html().length > 20){
                 $("#popap-enter .custom-form").html(data.find(".custom-form"));
               }
               else{
                 $("#popap-enter .custom-form").html(data.find(".header-text"));
                 setTimeout(function () { 
                  $('#popap-enter').modal('hide'); 
                  },6000);
               }
           }
         });
        //  $($form).find('.btn-form').prop('disabled', false);
    e.preventDefault();
    return false;
  });


});